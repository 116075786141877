<template>
  <div class="route-view finance_insurance_index">
    <a-row :gutter="30">
      <a-col :span="5">
        <div class="top"></div>
        <div class="columns">
          <div class="item">
            <router-link :to="{ name: 'finance_insurance_credit' }">信贷</router-link>
          </div>
          <div class="item">
            <router-link :to="{ name: 'finance_insurance_insurance' }">保险</router-link>
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <router-view :key="key" />
      </a-col>
    </a-row>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {

    }
  },
  components: {

  },
  computed: {
    key() {
      return this.$route.path;
    },
  },
  created() {
      console.log(this.$route)
  },
}
</script>

<style scoped lang="scss">
.finance_insurance_index {
  .top {
    height: 60px;
    background: url("~@/assets/images/finance_insurance.png") no-repeat left top #68cba5;
    background-size: 90% auto;
    border-bottom: 4px solid #008840;
  }
  .columns {
    .item {
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin-top: 10px;
      background: #e5effc;
      font-size: 18px;
      letter-spacing: 10px;
      a {
        display: block;
        text-decoration: none;
        color: #333;
      }
    }
  }
}
</style>
